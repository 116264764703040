import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import { CategoryContainer } from "./category"

const i18n = {
  noContent: "Kategoride içerik bulunamadı"
}

const Category = ({ location, pageContext }) => {
  const { articles, name } = pageContext;
  const langKey = 'tr'
  return (
    <Layout location={location}>
      <CategoryContainer langkey={langKey} i18n={i18n} articles={articles} name={name} />
    </Layout>
  )
}

export default Category

Category.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object.isRequired,
};